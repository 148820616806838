import React from 'react';
import './HomePage.scss';
import { Link } from 'react-router-dom';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';

interface HomePageProps {}
const HomePage: React.FC<HomePageProps> = (props) => {
	function listEnd() {
		return <div className={'listEnd'} />;
	}

	function listRow(bookTitle: string, rowType: 'audiobook' | 'pdf', linkTo?: string) {
		return (
			<Link to={linkTo || '#'} className={'listRow'}>
				{rowType === 'audiobook' ? (
					<RecordVoiceOverOutlinedIcon className={'icon'} />
				) : (
					<BookOutlinedIcon className={'icon'} />
				)}
				<div className={'rowTitle'}>{bookTitle}</div>
			</Link>
		);
	}

	function listTitle(title: string) {
		return <div className={'listTitle'}>{title}</div>;
	}
	return (
		<div className={'homePage'}>
			<div className={'pageWrapper'}>
				<div className={'titleText'}>
					Welcome to <br /> the Lunt Library!
				</div>
				<div className={'listsWrapper'}>
					<div className={'list'}>
						{listTitle('Audiobooks')}
						{listRow("Papa's Autobiography", 'audiobook', '/audiobooks/papas-autobiography?ch=1')}
						{listRow('', 'audiobook')}
						{listRow('', 'audiobook')}
						{listRow('', 'audiobook')}
					</div>
					<div className={'list'}>
						{listTitle('PDFs')}
						{listRow("Papa's Autobiography", 'pdf', '/pdfs/papas-autobiography')}
						{listRow('Clarence L. Lunt (1896-1939)', 'pdf', '/pdfs/clarence-lunt-biography')}
						{listRow('', 'pdf')}
						{listRow('', 'pdf')}
						{listEnd()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
