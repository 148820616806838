import React, { useEffect, useState } from 'react';
import './PdfViewerPage.scss';
import { Link, useParams } from 'react-router-dom';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Page } from 'react-pdf';
import { Box } from '@material-ui/core';
import useWindowWidth from '../../customHooks/useWindowWidth';

interface PdfViewerPageProps {}
const PdfViewerPage: React.FC<PdfViewerPageProps> = (props) => {
	const params = useParams();
	const width = useWindowWidth();

	const [pdfUrl, setPdfUrl] = useState();
	const [numPages, setNumPages] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);

	useEffect(() => {
		async function getPdf(pdfName: string) {
			try {
				const url: { data: any } = await axios.get(`/api/v1/pdfs?fileName=${pdfName}`);
				setPdfUrl(url.data);
			} catch (e) {
				console.log(e);
			}
		}
		getPdf(params.pdfName);
	}, []);

	function onDocumentLoadSuccess({ numPages }: any) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	function changePage(offset: number) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	function previousPage() {
		changePage(-1);
	}

	function nextPage() {
		changePage(1);
	}

	return (
		<div className={'pdfViewerPage'}>
			<div className={'pageWrapper'}>
				<div className={'backArrowWrapper'}>
					<Link to={'/'} className={'backButton'}>
						<ArrowBackIcon className={'arrowIcon'} />
						<div className={'backText'}>Back</div>
					</Link>
				</div>
				<Box
					className={'pdfWrapper'}
					width={Math.min(width * 0.9, 800)}
					height={Math.min(width * 0.9, 800) * 1.29375}
				>
					<Document className={'pdfViewer'} file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
						<Page pageNumber={pageNumber} width={Math.min(width * 0.9, 800)} />
					</Document>
				</Box>
				<Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
					<p>
						Page: {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
					</p>
					<Box display={'flex'}>
						<button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
							<>
								<ArrowBackIcon className={'arrowIcon'} />
							</>
						</button>
						<button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
							<>
								<ArrowForwardIcon className={'arrowIcon'} />
							</>
						</button>
					</Box>
				</Box>
			</div>
		</div>
	);
};

export default PdfViewerPage;
