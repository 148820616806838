import React from 'react';
import './Header.scss';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import {Link} from "react-router-dom";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = (props) => {
	return (
		<Link to={'/'} className={'header'}>
			<LocalLibraryOutlinedIcon className={'libraryIcon'} />
			<div className={'title'}>The Lunt Library</div>
		</Link>
	);
};

export default Header;
