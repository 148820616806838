import React, { createRef, RefObject, useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import H5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';
import './AudiobookPlayerPage.scss';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';

interface AudiobookPlayerProps {}
interface BookDetails {
	title: string;
	subtitle?: string;
	chapters: string[];
}
const AudiobookPlayerPage: React.FC<AudiobookPlayerProps> = (props) => {
	const params = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [bookDetails, setBookDetails] = useState<BookDetails>();
	const [chapterAudio, setChapterAudio] = useState<string>();
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const audioPlayer: RefObject<any> = createRef();

	const { bookTitle } = params;
	const chapterNumber = Number(searchParams.get('ch'));

	useEffect(() => {
		async function getBookDetails(title: string, chapterNumber: number) {
			try {
				const bookDetails: { data: BookDetails } = await axios.get(
					`/api/v1/audiobooks/details?title=${bookTitle}`
				);
				setBookDetails(bookDetails.data);
				if (chapterNumber < 1 || chapterNumber > bookDetails.data.chapters.length)
					setSearchParams(new URLSearchParams('ch=1'), { replace: true });
			} catch (e) {
				console.log(e);
			}
		}
		if (!chapterNumber) {
			setSearchParams(new URLSearchParams('ch=1'));
		}
		getBookDetails(bookTitle, chapterNumber);
	}, []);

	useEffect(() => {
		if (!chapterNumber) return;
		async function getChapterAudio(title: string, chapter: number) {
			setIsLoading(true);

			try {
				const chapterAudioFile: { data: string } = await axios.get(
					`/api/v1/audiobooks/chapter-audio?title=${bookTitle}&ch=${chapterNumber}`
				);
				setChapterAudio(chapterAudioFile.data);
				setIsLoading(false);
			} catch (e) {
				console.log(e);
				setSearchParams(new URLSearchParams('ch=1'));
				setIsLoading(false);
			}
		}
		getChapterAudio(bookTitle, chapterNumber);
	}, [chapterNumber]);

	function handleChapterClick(chapterNumber: number) {
		setSearchParams(new URLSearchParams(`ch=${chapterNumber + 1}`));
	}

	function listRow(chapterName: string, index: number) {
		return (
			<div key={chapterName} className={'listRow'} onClick={() => handleChapterClick(index)}>
				{index != chapterNumber - 1 ? (
					<BookmarkBorderIcon className={'icon'} />
				) : (
					<VolumeUpIcon className={'icon'} />
				)}
				<div className={'rowName'}>{chapterName}</div>
			</div>
		);
	}

	function renderChapterRows() {
		return bookDetails?.chapters.map((chapter, index) => {
			return listRow(chapter, index);
		});
	}

	function listTitle(title: string) {
		return <div className={'listTitle'}>{title}</div>;
	}

	function renderChapterList() {
		if (!bookDetails || !bookDetails.chapters || !bookDetails.chapters.length) return;

		return (
			<div className={'chapterList'}>
				{listTitle('Chapters')}
				{renderChapterRows()}
			</div>
		);
	}

	function handleOnClickPrevious() {
		if (audioPlayer.current.audio.current.currentTime > 2) {
			audioPlayer.current.audio.current.currentTime = 0;
		} else {
			if (chapterNumber > 1) setSearchParams(new URLSearchParams(`ch=${chapterNumber - 1}`));
		}
	}

	function handleOnClickNext() {
		if (bookDetails && bookDetails.chapters && chapterNumber < bookDetails.chapters.length)
			setSearchParams(new URLSearchParams(`ch=${chapterNumber + 1}`));
	}

	function getCurrentChapter() {
		if (!bookDetails || !bookDetails.chapters) return 'Chapter 1';
		return `Chapter ${chapterNumber} - ${bookDetails.chapters[chapterNumber - 1]}`;
	}

	return (
		<div className={'audiobookPlayerPage'}>
			<div className={'pageWrapper'}>
				<div className={'backArrowWrapper'}>
					<Link to={'/'} className={'backButton'}>
						<ArrowBackIcon className={'arrowIcon'} />
						<div className={'backText'}>Back</div>
					</Link>
				</div>
				<div className={'bookTitle'}>{bookDetails?.title || 'Title'}</div>
				{bookDetails?.subtitle && <div className={'bookSubtitle'}>{bookDetails.subtitle}</div>}
				<div className={'currentChapter'}>{getCurrentChapter()}</div>
				{isLoading ? (
					<div className={'progressWrapper'}>
						<CircularProgress style={{ color: 'black' }} />
					</div>
				) : (
					<H5AudioPlayer
						ref={audioPlayer}
						src={chapterAudio}
						showSkipControls
						autoPlayAfterSrcChange={isPlaying}
						onClickNext={handleOnClickNext}
						onClickPrevious={handleOnClickPrevious}
						onEnded={handleOnClickNext}
						onPlaying={() => setIsPlaying(true)}
						onPause={() => setIsPlaying(false)}
					/>
				)}
				{renderChapterList()}
			</div>
		</div>
	);
};

export default AudiobookPlayerPage;
