import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import AudiobookPlayerPage from './pages/audiobookPlayerPage/AudiobookPlayerPage';
import useWindowResizeMobile from './customHooks/useWindowResizeMobile';
import Header from './components/header/Header';
import HomePage from './pages/home/HomePage';
import PdfViewerPage from './pages/pdfViewerPage/PdfViewerPage';

function AutobiographyGaryLunt() {
	const resizeCount = useWindowResizeMobile();

	useEffect(() => {
		const timer = setTimeout(() => {
			document
				.getElementsByTagName('html') // @ts-ignore
				[0].style.setProperty('--vh', `${window.innerHeight / 100}px`);
		}, 50);

		return () => {
			clearTimeout(timer);
		};
	}, [resizeCount]);
}

function NotFound() {
	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
			<h2>Page Not Found</h2>
		</div>
	);
}

function App() {
	return (
		<BrowserRouter>
			<div className="App">
				<Header />
				<div className={'page'}>
					<Routes>
						<Route path={'/'} element={<HomePage />} />
						<Route path={'/audiobooks/:bookTitle'} element={<AudiobookPlayerPage />} />
						<Route path={'/pdfs/:pdfName'} element={<PdfViewerPage />} />
						<Route path={'/*'} element={NotFound()} />
					</Routes>
				</div>
			</div>
		</BrowserRouter>
	);
}

export default App;
